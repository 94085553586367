//
// Skin: Blue
// Mode: Light
//

$blue:         #245c6e !default;
$yellow:        #ffdd59 !default;

$primary:       $blue !default;
$secondary:     $yellow !default;
